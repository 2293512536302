import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import QRCode from 'qrcode.react';
import abi from './SmartPasticceriaABI.json';
import './ListaPasticceria.css';
import {Link} from 'react-router-dom';

let web3;
let contract;

function ListaPasticceria() {
    const [pasticcerie, setPasticcerie] = useState([]);
    const [pasticceriaDetail, setPasticceriaDetail] = useState(null);
    const [orderId, setOrderId] = useState('');

    useEffect(() => {
        const initWeb3 = async () => {
            const infuraApiKey = 'YOUR_INFURA_API_KEY'; // Sostituisci con la tua chiave API Infura
            const infuraEndpoint = `https://sepolia.infura.io/v3/7b4584fcaa0c43a39a85583b18e25817`; // Cambia 'mainnet' se desideri una rete diversa

            web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint));

            // Imposta la tua chiave privata
            const privateKey = '2f9d97e9a41e562d6b7d5cf1e86ce3338ad718c18617158a8cce3554eadc8ada'; // Sostituisci con la tua chiave privata
            web3.eth.accounts.wallet.add(privateKey);

            const address = '0xe8ac024517398fb87168b653b9c0691618668bdb';
            contract = new web3.eth.Contract(abi, address);
        };

        const fetchPasticcerie = async () => {
            try {
                await initWeb3();
                const pasticcerie = await contract.methods.getAllPasticcerias().call();
                setPasticcerie(pasticcerie);
            } catch (error) {
                console.error('Error fetching pasticcerie:', error);
            }
        };

        fetchPasticcerie();
    }, []);

    const fetchPasticceriaDetail = async (orderId) => {
        try {
            const pasticceria = await contract.methods.getPasticceriaByOrderId(orderId).call();
            setPasticceriaDetail(pasticceria);
        } catch (error) {
            console.error('Error fetching cioccolato detail:', error);
        }
    };

    return (
        <div className="container">
            <div className="divStyle">
                <h1 className="h1" style={{fontFamily: 'Monaco'}}>Ricerca Dolci</h1>
                <input
                    type="text"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                    placeholder="Inserisci ID dell'ordine"
                    className="inputStyle"
                />
                <button onClick={() => fetchPasticceriaDetail(orderId)} className="buttonStyle">Ricerca</button>
                {pasticceriaDetail && (
                    <table className="tableStyle">
                        <thead>
                        <tr>
                            <th className="th" colSpan="7" style={{textAlign: 'center'}}>Pasta Lievitata</th>
                            <th className="th" colSpan="6" style={{textAlign: 'center'}}>Pasta Sfoglia</th>
                            <th className="th" colSpan="4" style={{textAlign: 'center'}}>Pasta Frolla</th>
                            <th className="th" style={{textAlign: 'center'}}>Farcitura</th>
                            <th className="th" colSpan="2" style={{textAlign: 'center'}}>Cottura</th>
                            <th className="th" style={{textAlign: 'center'}}></th>
                        </tr>
                        <tr>
                            <th className="th" style={{textAlign: 'center'}}>Farina</th>
                            <th className="th" style={{textAlign: 'center'}}>Lievito</th>
                            <th className="th" style={{textAlign: 'center'}}>Zucchero</th>
                            <th className="th" style={{textAlign: 'center'}}>Uova</th>
                            <th className="th" style={{textAlign: 'center'}}>Latte</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Impasto</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Lievitazione</th>
                            <th className="th" style={{textAlign: 'center'}}>Farina</th>
                            <th className="th" style={{textAlign: 'center'}}>Burro</th>
                            <th className="th" style={{textAlign: 'center'}}>Acqua</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Impasto</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Riposo</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Riposo</th>
                            <th className="th" style={{textAlign: 'center'}}>Farina</th>
                            <th className="th" style={{textAlign: 'center'}}>Burro</th>
                            <th className="th" style={{textAlign: 'center'}}>Uova</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Impasto</th>
                            <th className="th" style={{textAlign: 'center'}}>Farcitura</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Cottura</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Cottura</th>
                            <th className="th" style={{textAlign: 'center'}}>QR Code</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="td">{pasticceriaDetail.pasta[0]} </td>
                            <td className="td">{pasticceriaDetail.pasta[1]} </td>
                            <td className="td">{pasticceriaDetail.pasta[2]} </td>
                            <td className="td">{pasticceriaDetail.pasta[3]} </td>
                            <td className="td">{pasticceriaDetail.pasta[4]} </td>
                            <td className="td">{pasticceriaDetail.tempi1[0]} </td>
                            <td className="td">{pasticceriaDetail.tempi1[1]} </td>
                            <td className="td">{pasticceriaDetail.pastasfoglia[0]} </td>
                            <td className="td">{pasticceriaDetail.pastasfoglia[1]} </td>
                            <td className="td">{pasticceriaDetail.pastasfoglia[2]} </td>
                            <td className="td">{pasticceriaDetail.tempi2[0]} </td>
                            <td className="td">{pasticceriaDetail.tempi2[1]} </td>
                            <td className="td">{pasticceriaDetail.temperatura1} </td>
                            <td className="td">{pasticceriaDetail.pastafrolla[0]} </td>
                            <td className="td">{pasticceriaDetail.pastafrolla[1]} </td>
                            <td className="td">{pasticceriaDetail.pastafrolla[2]} </td>
                            <td className="td">{pasticceriaDetail.tempimp} </td>
                            <td className="td">{pasticceriaDetail.temperatura2} </td>
                            <td className="td">{pasticceriaDetail.tempcot} </td>
                            <td className="td">{pasticceriaDetail.farcitura} </td>
                            <td className="td">
                                <Link to={`/dettaglio-pasticceria/${pasticceriaDetail.orderId}`}>
                                    <QRCode
                                        value={`${window.location.origin}/dettaglio-pasticceria/${pasticceriaDetail.orderId}`}/>
                                </Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </div>
            <div className="divStyle" style={{maxWidth: '100%'}}>
                <h1 className="h1" style={{fontFamily: 'Monaco'}}>Tutti i Dolci</h1>
                <table className="tableStyle">
                    <thead>
                    <tr>
                        <th className="th" style={{textAlign: 'center'}}></th>
                        <th className="th" colSpan="7" style={{textAlign: 'center'}}>Pasta Lievitata</th>
                        <th className="th" colSpan="6" style={{textAlign: 'center'}}>Pasta Sfoglia</th>
                        <th className="th" colSpan="4" style={{textAlign: 'center'}}>Pasta Frolla</th>
                        <th className="th" style={{textAlign: 'center'}}>Farcitura</th>
                        <th className="th" colSpan="2" style={{textAlign: 'center'}}>Cottura</th>
                        <th className="th" style={{textAlign: 'center'}}></th>
                    </tr>
                    <tr>
                        <th className="th" style={{textAlign: 'center'}}>Numero Ordine</th>
                        <th className="th" style={{textAlign: 'center'}}>Farina</th>
                        <th className="th" style={{textAlign: 'center'}}>Lievito</th>
                        <th className="th" style={{textAlign: 'center'}}>Zucchero</th>
                        <th className="th" style={{textAlign: 'center'}}>Uova</th>
                        <th className="th" style={{textAlign: 'center'}}>Latte</th>
                        <th className="th" style={{textAlign: 'center'}}>Tempo Impasto</th>
                        <th className="th" style={{textAlign: 'center'}}>Tempo Lievitazione</th>
                        <th className="th" style={{textAlign: 'center'}}>Farina</th>
                        <th className="th" style={{textAlign: 'center'}}>Burro</th>
                        <th className="th" style={{textAlign: 'center'}}>Acqua</th>
                        <th className="th" style={{textAlign: 'center'}}>Tempo Impasto</th>
                        <th className="th" style={{textAlign: 'center'}}>Tempo Riposo</th>
                        <th className="th" style={{textAlign: 'center'}}>Temperatura Riposo</th>
                        <th className="th" style={{textAlign: 'center'}}>Farina</th>
                        <th className="th" style={{textAlign: 'center'}}>Burro</th>
                        <th className="th" style={{textAlign: 'center'}}>Uova</th>
                        <th className="th" style={{textAlign: 'center'}}>Tempo Impasto</th>
                        <th className="th" style={{textAlign: 'center'}}>Farcitura</th>
                        <th className="th" style={{textAlign: 'center'}}>Temperatura Cottura</th>
                        <th className="th" style={{textAlign: 'center'}}>Tempo Cottura</th>
                        <th className="th" style={{textAlign: 'center'}}>QR Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pasticcerie.map((pasticceria, index) => (
                        <tr key={pasticceria.orderId}>
                            <td className="td">{index + 1}</td>
                            <td className="td">{pasticceria.pasta[0]} </td>
                            <td className="td">{pasticceria.pasta[1]} </td>
                            <td className="td">{pasticceria.pasta[2]} </td>
                            <td className="td">{pasticceria.pasta[3]} </td>
                            <td className="td">{pasticceria.pasta[4]} </td>
                            <td className="td">{pasticceria.tempi1[0]} </td>
                            <td className="td">{pasticceria.tempi1[1]} </td>
                            <td className="td">{pasticceria.pastasfoglia[0]} </td>
                            <td className="td">{pasticceria.pastasfoglia[1]} </td>
                            <td className="td">{pasticceria.pastasfoglia[2]} </td>
                            <td className="td">{pasticceria.tempi2[0]} </td>
                            <td className="td">{pasticceria.tempi2[1]} </td>
                            <td className="td">{pasticceria.temperatura1} </td>
                            <td className="td">{pasticceria.pastafrolla[0]} </td>
                            <td className="td">{pasticceria.pastafrolla[1]} </td>
                            <td className="td">{pasticceria.pastafrolla[2]} </td>
                            <td className="td">{pasticceria.tempimp} </td>
                            <td className="td">{pasticceria.temperatura2} </td>
                            <td className="td">{pasticceria.tempcot} </td>
                            <td className="td">{pasticceria.farcitura} </td>
                            <td className="td">
                                <Link to={`/dettaglio-pasticceria/${pasticceria.orderId}`}>
                                    <QRCode
                                        value={`${window.location.origin}/dettaglio-pasticceria/${pasticceria.orderId}`}/>
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListaPasticceria;
