import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Importa il file CSS
const whitePaper = '/WHITEPAPER.PDF';

function CustomNavbar() {
  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark" style={{ backgroundColor: 'rgba(128, 199, 184, 0.6)', fontFamily: 'Monaco', height: '100px' }}>
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            src="/logobianco.png"
            alt="SmartPasticceria Logo"
            height="90"
            className="ml-2"
          />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link active" to="/">HOME</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/partner">PARTNER</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/whitepaper">WHITE-PAPER</Link>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={preventDefault}>
                PASTICCERIA
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/aggiungi-pasticceria">NEW DOLCE</Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/lista-pasticceria">DOLCI LIST</Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default CustomNavbar;
