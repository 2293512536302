import React, {useState, useEffect, useRef} from 'react';
import Web3 from 'web3';
import abi from './SmartPasticceriaABI.json';

let web3;
let contract;

function AggiungiPasticceria() {
    const [pasticceriaInfo, setPasticceriaInfo] = useState({ /* ... come prima ... */});
    const [metamaskDetected, setMetamaskDetected] = useState(true);
    const [selectedPhase, setSelectedPhase] = useState('PASTALIEVITATA'); // Stato iniziale
    const ownerAddress = '0x4423dEcD23330De7A5306Fd0F41Cb5715F93F0B7'; // Indirizzo del proprietario
    const [isOwner, setIsOwner] = useState(false);


    const audioRef = useRef();

    useEffect(() => {
        const initWeb3 = async () => {
            if (window.ethereum) {
                try {
                    await window.ethereum.request({method: 'eth_requestAccounts'});
                    web3 = new Web3(window.ethereum);

                    const accounts = await web3.eth.getAccounts();
                    console.log('Indirizzo dell\'utente connesso:', accounts[0]);
                    if (accounts[0].toLowerCase() === ownerAddress.toLowerCase()) {
                        // L'utente connesso è il proprietario
                        setIsOwner(true);
                    } else {
                        setIsOwner(false);
                    }
                    console.log("Test", setIsOwner)
                } catch (error) {
                    console.error('User denied account access');
                }
            } else if (window.web3 && window.web3.currentProvider) {
                web3 = new Web3(window.web3.currentProvider);
            } else {
                console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
                setMetamaskDetected(false);
                return;
            }

            const address = '0xe8ac024517398fb87168b653b9c0691618668bdb';
            if (web3) {
                contract = new web3.eth.Contract(abi, address);
            }
        };

        initWeb3();
    }, []);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setPasticceriaInfo({
            ...pasticceriaInfo,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const accounts = await web3.eth.getAccounts();
            const pasticceriaInput = {
                orderId: parseInt(pasticceriaInfo.orderId, 10),
                pasta: [pasticceriaInfo.farina1, pasticceriaInfo.lievito1, pasticceriaInfo.zucchero1, pasticceriaInfo.uova1, pasticceriaInfo.latte1],
                tempi1: [parseInt(pasticceriaInfo.tempoimpasto, 10), parseInt(pasticceriaInfo.tempolievitazione, 10)],
                pastasfoglia: [pasticceriaInfo.farina2, pasticceriaInfo.burro2, pasticceriaInfo.acqua2],
                tempi2: [parseInt(pasticceriaInfo.tempoimpasto2, 10),parseInt(pasticceriaInfo.temporiposo2, 10)],
                temperatura1: parseInt(pasticceriaInfo.temperaturariposo2, 10),
                pastafrolla: [pasticceriaInfo.farina3, pasticceriaInfo.burro3, pasticceriaInfo.uova3],
                tempimp: parseInt(pasticceriaInfo.tempoimpasto3, 10),
                temperatura2: parseInt(pasticceriaInfo.temperaturacottura, 10),
                tempcot: parseInt(pasticceriaInfo.tempocottura, 10),
                farcitura: pasticceriaInfo.farcitura
            };

            console.log('pasticceriaInput:', pasticceriaInput);

            const gasPrice = await web3.eth.getGasPrice();

            await contract.methods.addPasticceria(
                pasticceriaInput.orderId,
                pasticceriaInput.pasta,
                pasticceriaInput.tempi1,
                pasticceriaInput.pastasfoglia,
                pasticceriaInput.tempi2,
                pasticceriaInput.temperatura1,
                pasticceriaInput.pastafrolla,
                pasticceriaInput.tempimp,
                pasticceriaInput.temperatura2,
                pasticceriaInput.tempcot,
                pasticceriaInput.farcitura,
            ).send({
                from: accounts[0],
                gas: 2100000,
                gasPrice: gasPrice
            });

            console.log('Dati della cioccolata inviati:', pasticceriaInfo);

            setPasticceriaInfo({
                orderId: '',
                farina1:'',
                lievito1:'',
                zucchero1:'',
                uova1:'',
                latte1:'',
                tempoimpasto:'',
                tempolievitazione:'',
                farina2:'',
                burro2:'',
                acqua2:'',
                tempoimpasto2:'',
                temporiposo2:'',
                temperaturariposo2:'',
                farina3:'',
                burro3:'',
                uova3:'',
                tempoimpasto3:'',
                temperaturacottura:'',
                tempocottura:'',
                farcitura:'',
            });
            audioRef.current.play();
        } catch (error) {
            console.error('Errore nell’invio dei dati:', error.message);
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            {(!isOwner) && (
                <div
                    style={{
                        backgroundColor: 'rgba(128, 199, 184, 0.6)',
                        color: 'white',
                        textAlign: 'center',
                        marginTop: '10px', // Riduci lo spazio superiore
                        padding: '10px',
                        borderRadius: '10px',
                        maxWidth: '600px',
                        margin: '10px auto',
                    }}
                >
                    ATTENZIONE: Solo il proprietario per utilizzare questa funzione!
                </div>
            )}
            <div style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
                <img
                    src="/robot1.png"
                    alt="Robot"
                    style={{
                        width: '250px',
                        height: '250px',
                        position: 'absolute',
                        bottom: '15px', // Set this to align the image to the bottom
                        right: '680px',
                        zIndex: 1
                    }}
                />
                <img
                    src="/robot2.png"
                    alt="Robot 2"
                    style={{
                        width: '100px',
                        height: '100px',
                        position: 'absolute',
                        top: '20px',
                        right: '80px',
                        zIndex: 1
                    }}
                />
                <div
                    style={{
                        backgroundColor: 'rgba(128, 199, 184, 0.6)',
                        padding: '10px',
                        borderRadius: '10px',
                        maxWidth: '620px',
                        margin: '20px',
                        marginLeft: 'auto',
                        marginRight: '100px',
                        textAlign: 'center',
                        marginTop: '60px',
                        height: '680px', // Imposta un'altezza fissa desiderata
                        overflow: 'auto', // Aggiunge una barra di scorrimento se il contenuto supera l'altezza
                        position: 'relative', // Imposta la posizione relativa
                        width: "600px",
                    }}
                >
                    <img
                        src="/logo.png"
                        alt="SmartPasticceria Logo"
                        style={{
                            marginBottom: '5px',
                            maxWidth: '200px',
                        }}
                    />

                    <div>
                        <div className="mb-2" style={{display: 'flex', alignItems: 'center', marginLeft: '10%'}}>
                            <label htmlFor="orderId" className="form-label" style={{color: 'white', width: '180px'}}>Order
                                ID</label>
                            <input
                                type="number"
                                className="form-control"
                                id="orderId"
                                name="orderId"
                                value={pasticceriaInfo.orderId}
                                onChange={handleInputChange}
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                    border: '1px solid white',
                                    width: '80px'
                                }}
                            />
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'PASTALIEVITATA' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('PASTALIEVITATA')}
                        >
                            PASTA LIEVITATA
                            {selectedPhase === 'PASTALIEVITATA' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'PASTASFOGLIA' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('PASTASFOGLIA')}
                        >
                            PASTA SFOGLIA
                            {selectedPhase === 'PASTASFOGLIA' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'PASTAFROLLA' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('PASTAFROLLA')}
                        >
                            PASTA FROLLA
                            {selectedPhase === 'PASTAFROLLA' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'FARCITURA' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('FARCITURA')}
                        >
                            FARCITURA
                            {selectedPhase === 'FARCITURA' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'COTTURA' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('COTTURA')}
                        >
                            COTTURA
                            {selectedPhase === 'COTTURA' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                    </div>


                    <br></br>
                    {/* FASE PASTA LIEVITATA */}
                    {selectedPhase === 'PASTALIEVITATA' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="farina1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Farina</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="farina1"
                                    name="farina1"
                                    value={pasticceriaInfo.farina1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="lievito1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Lievito</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lievito1"
                                    name="lievito1"
                                    value={pasticceriaInfo.lievito1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="zucchero1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Zucchero</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="zucchero1"
                                    name="zucchero1"
                                    value={pasticceriaInfo.zucchero1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="uova1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Uova</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="uova1"
                                    name="uova1"
                                    value={pasticceriaInfo.uova1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="latte1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Latte</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="latte1"
                                    name="latte1"
                                    value={pasticceriaInfo.latte1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="tempoimpasto" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Tempo Impasto</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tempoimpasto"
                                    name="tempoimpasto"
                                    value={pasticceriaInfo.tempoimpasto}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="tempolievitazione" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Tempo Lievitazione</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tempolievitazione"
                                    name="tempolievitazione"
                                    value={pasticceriaInfo.tempolievitazione}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE PASTA SFOGLIA */}
                    {selectedPhase === 'PASTASFOGLIA' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="farina2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Farina</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="farina2"
                                    name="farina2"
                                    value={pasticceriaInfo.farina2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="burro2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Burro</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="burro2"
                                    name="burro2"
                                    value={pasticceriaInfo.burro2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="acqua2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Acqua</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="acqua2"
                                    name="acqua2"
                                    value={pasticceriaInfo.acqua2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="tempoimpasto2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Tempo Impasto</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tempoimpasto2"
                                    name="tempoimpasto2"
                                    value={pasticceriaInfo.tempoimpasto2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temporiposo2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Tempo Riposo</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temporiposo2"
                                    name="temporiposo2"
                                    value={pasticceriaInfo.temporiposo2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperaturariposo2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura Riposo</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperaturariposo2"
                                    name="temperaturariposo2"
                                    value={pasticceriaInfo.temperaturariposo2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE PASTA FROLLA */}
                    {selectedPhase === 'PASTAFROLLA' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="farina3" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Farina</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="farina3"
                                    name="farina3"
                                    value={pasticceriaInfo.farina3}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="burro3" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Burro</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="burro3"
                                    name="burro3"
                                    value={pasticceriaInfo.burro3}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="uova3" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Uova</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="uova3"
                                    name="uova3"
                                    value={pasticceriaInfo.uova3}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="tempoimpasto3" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Tempo Impasto</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tempoimpasto3"
                                    name="tempoimpasto3"
                                    value={pasticceriaInfo.tempoimpasto3}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE FARCITURA  */}
                    {selectedPhase === 'FARCITURA' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="farcitura" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Tipo/Peso</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="farcitura"
                                    name="farcitura"
                                    value={pasticceriaInfo.farcitura}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE COTTURA */}
                    {selectedPhase === 'COTTURA' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperaturacottura" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperaturacottura"
                                    name="temperaturacottura"
                                    value={pasticceriaInfo.temperaturacottura}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="tempocottura" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Tempo</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tempocottura"
                                    name="tempocottura"
                                    value={pasticceriaInfo.tempocottura}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}




                    <hr
                        style={{
                            borderColor: 'white',
                            borderStyle: 'dashed',
                            borderWidth: '2px',
                            position: 'absolute', // Imposta la posizione assoluta
                            bottom: '55px', // Posiziona l'elemento 30px sopra il bottone "Aggiungi Choco"
                            left: '50%', // Centra l'elemento orizzontalmente
                            transform: 'translateX(-50%)', // Centra l'elemento orizzontalmente
                            width: '100%', // Imposta la larghezza al 100% per coprire tutto il contenitore
                        }}
                    />

                    <button type="submit" className="btn btn-light" style={{
                        color: 'black',
                        marginTop: '10px',
                        position: 'absolute', // Imposta la posizione assoluta
                        bottom: '20px', // Posiziona il bottone in fondo al div
                        left: '50%', // Centra il bottone orizzontalmente
                        transform: 'translateX(-50%)', // Centra il bottone orizzontalmente
                    }}>
                        Aggiungi Dolce
                    </button>
                </div>
                <audio ref={audioRef} src="/sound.mp3" preload="auto"></audio>
            </div>
        </form>
    );
}

export default AggiungiPasticceria;
