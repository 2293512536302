import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import abi from './SmartPasticceriaABI.json';
import './DettaglioPasticceria.css';
import {useParams} from 'react-router-dom'; // Import useParams

let web3;
let contract;

function DettaglioPasticceria() {
    const {orderId} = useParams(); // Extract orderId from URL parameters
    const [pasticceriaDetail, setPasticceriaDetail] = useState(null);

    useEffect(() => {
        const initWeb3 = async () => {
            const infuraApiKey = 'YOUR_INFURA_API_KEY'; // Sostituisci con la tua chiave API Infura
            const infuraEndpoint = `https://sepolia.infura.io/v3/7b4584fcaa0c43a39a85583b18e25817`; // Cambia 'mainnet' se desideri una rete diversa

            web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint));

            // Imposta la tua chiave privata
            const privateKey = '2f9d97e9a41e562d6b7d5cf1e86ce3338ad718c18617158a8cce3554eadc8ada'; // Sostituisci con la tua chiave privata
            web3.eth.accounts.wallet.add(privateKey);

            const address = '0xe8ac024517398fb87168b653b9c0691618668bdb';
            contract = new web3.eth.Contract(abi, address);
        };

        const fetchPasticceriaDetail = async () => {
            try {
                await initWeb3();
                if (orderId) {
                    const pasticceria = await contract.methods.getPasticceriaByOrderId(orderId).call();
                    setPasticceriaDetail(pasticceria);
                }
            } catch (error) {
                console.error('Error fetching pasticceria detail:', error);
            }
        };

        fetchPasticceriaDetail();
    }, [orderId]); // Add orderId as a dependency for useEffect

    return (
        <div className="container">
            <div className="divStyle" style={{maxWidth: '100%'}}>
                <h1>Dettagli Dolce</h1>
                {pasticceriaDetail ? (
                    <table className="tableStyle">
                        <thead>
                        <tr>
                            <th className="th" colSpan="7" style={{textAlign: 'center'}}>Pasta Lievitata</th>
                            <th className="th" colSpan="6" style={{textAlign: 'center'}}>Pasta Sfoglia</th>
                            <th className="th" colSpan="4" style={{textAlign: 'center'}}>Pasta Frolla</th>
                            <th className="th" style={{textAlign: 'center'}}>Farcitura</th>
                            <th className="th" colSpan="2" style={{textAlign: 'center'}}>Cottura</th>
                        </tr>
                        <tr>
                            <th className="th" style={{textAlign: 'center'}}>Farina</th>
                            <th className="th" style={{textAlign: 'center'}}>Lievito</th>
                            <th className="th" style={{textAlign: 'center'}}>Zucchero</th>
                            <th className="th" style={{textAlign: 'center'}}>Uova</th>
                            <th className="th" style={{textAlign: 'center'}}>Latte</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Impasto</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Lievitazione</th>
                            <th className="th" style={{textAlign: 'center'}}>Farina</th>
                            <th className="th" style={{textAlign: 'center'}}>Burro</th>
                            <th className="th" style={{textAlign: 'center'}}>Acqua</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Impasto</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Riposo</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Riposo</th>
                            <th className="th" style={{textAlign: 'center'}}>Farina</th>
                            <th className="th" style={{textAlign: 'center'}}>Burro</th>
                            <th className="th" style={{textAlign: 'center'}}>Uova</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Impasto</th>
                            <th className="th" style={{textAlign: 'center'}}>Farcitura</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Cottura</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo Cottura</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="td">{pasticceriaDetail.pasta[0]} </td>
                            <td className="td">{pasticceriaDetail.pasta[1]} </td>
                            <td className="td">{pasticceriaDetail.pasta[2]} </td>
                            <td className="td">{pasticceriaDetail.pasta[3]} </td>
                            <td className="td">{pasticceriaDetail.pasta[4]} </td>
                            <td className="td">{pasticceriaDetail.tempi1[0]} </td>
                            <td className="td">{pasticceriaDetail.tempi1[1]} </td>
                            <td className="td">{pasticceriaDetail.pastasfoglia[0]} </td>
                            <td className="td">{pasticceriaDetail.pastasfoglia[1]} </td>
                            <td className="td">{pasticceriaDetail.pastasfoglia[2]} </td>
                            <td className="td">{pasticceriaDetail.tempi2[0]} </td>
                            <td className="td">{pasticceriaDetail.tempi2[1]} </td>
                            <td className="td">{pasticceriaDetail.temperatura1} </td>
                            <td className="td">{pasticceriaDetail.pastafrolla[0]} </td>
                            <td className="td">{pasticceriaDetail.pastafrolla[1]} </td>
                            <td className="td">{pasticceriaDetail.pastafrolla[2]} </td>
                            <td className="td">{pasticceriaDetail.tempimp} </td>
                            <td className="td">{pasticceriaDetail.temperatura2} </td>
                            <td className="td">{pasticceriaDetail.tempcot} </td>
                            <td className="td">{pasticceriaDetail.farcitura} </td>
                        </tr>
                        </tbody>
                    </table>
                ) : (
                    <p>Caricamento in corso...</p>
                )}
            </div>
            {/* Aggiungi il div per le spiegazioni sugli ingredienti */}
            <div className="ingredientsExplanation">
                <h2>Origine degli Ingredienti</h2>
                <p>
                    La nostra pasticceria si impegna a utilizzare ingredienti di alta qualità provenienti da fonti
                    affidabili. Ecco un breve riassunto dell'origine degli ingredienti principali:
                </p>
                <ul>
                    <li><strong>Farina:</strong> La nostra farina pregiata proviene da mulini selezionati, garantendo
                        una consistenza perfetta e un sapore delizioso ai nostri prodotti da forno.
                    </li>
                    <li><strong>Uova:</strong> Le uova utilizzate nelle nostre preparazioni sono fornite da allevamenti
                        fidati, contribuendo a dare struttura e morbidezza ai nostri dolci.
                    </li>
                    <li><strong>Zucchero:</strong> Lo zucchero utilizzato nei nostri dolci contribuisce a donare
                        dolcezza e
                        equilibrio di sapori, assicurando un'esperienza gustativa straordinaria.
                    </li>
                </ul>
                <p>
                    Siamo orgogliosi di offrire ai nostri clienti una pasticceria di alta qualità con ingredienti
                    selezionati con cura e provenienti da fonti affidabili.
                </p>
            </div>
        </div>
    );
}

export default DettaglioPasticceria;
