import React, { useState } from 'react'; // Importa useState da React
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CustomNavbar from './Navbar';
import Home from './Home';
import Partner from './Partner';
import AggiungiPasticceria from './AggiungiPasticceria'; // Importa il componente AggiungiChoco
import ListaPasticceria from './ListaPasticceria'; // Importa il componente ListaPizze
import Document from './Documents'; // Importa il componente Document
import DettaglioPasticceria from './DettaglioPasticceria'; // Importa il componente DettaglioChoco

function AppRouter() {
  const [pasticceriaDetail, setPasticceriaDetail] = useState(null); // Inizializza pasticceriaDetail utilizzando useState

  return (
    <Router>
      <CustomNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/aggiungi-pasticceria" element={<AggiungiPasticceria />} /> {/* Aggiungi la route per AggiungiPasticceria */}
        <Route path="/lista-pasticceria" element={<ListaPasticceria />} /> {/* Aggiungi la route per ListaPasticceria */}
        <Route path="/dettaglio-pasticceria/:orderId" element={<DettaglioPasticceria />} />
        <Route path="/whitepaper" element={<Document />} /> {/* Add this line */}

      </Routes>
    </Router>
  );
}

export default AppRouter;
